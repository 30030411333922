<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :can-cancel="false" />
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
        <CRow>
            <CCol sm="4">
                <CRow>
                    <CCol sm="12">
                    <CCard>
                        <CCardHeader>
                            <CIcon name="cil-casino"/><strong> {{ avatar.Name }} </strong>
                            <div class="card-header-actions">
                                <CLink class="card-header-action btn-minimize" @click="toggleAll">
                                    <CIcon :name="`cil-chevron-${showAll ? 'bottom' : 'top'}`"/>
                                </CLink>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <dl class="row">
                                <dt class="col-sm-3">Name</dt>
                                <dd class="col-sm-9">{{ avatar.name }}</dd>

                                <dt class="col-sm-3">World ID</dt>
                                <dd class="col-sm-9">{{ avatar.worldId }}</dd>

                                <dt class="col-sm-3">Created</dt>
                                <dd class="col-sm-9">{{ avatar.created | formatDate }}</dd>

                                <dt class="col-sm-3">Updated</dt>
                                <dd class="col-sm-9">{{ avatar.updated | formatDate }}</dd>
                            </dl>
                        </CCardBody>
                    </CCard>
                    </CCol>
                </CRow>
            </CCol>
            <CCol sm="6">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-user"/><strong> {{ player.userName }} </strong> (Owner)
                        <div class="card-header-actions">
                            <CBadge :color="$config.userClr(player.flags)">{{ $config.userTxt(player.flags) }}</CBadge>
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <dl class="row">
                            <dt class="col-sm-3">Username</dt>
                            <dd class="col-sm-9">{{ player.userName }}</dd>

                            <dt class="col-sm-3">Email</dt>
                            <dd class="col-sm-9">{{ player.email }}</dd>

                            <dt class="col-sm-3">Slots</dt>
                            <dd class="col-sm-9">{{ player.characterSlots }}</dd>

                            <dt class="col-sm-3">Updated</dt>
                            <dd class="col-sm-9">{{ player.updated | formatDate }}</dd>

                            <dt class="col-sm-3">Key</dt>
                            <dd class="col-sm-9">{{ player.id }}</dd>
                        </dl>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-puzzle"/><strong> Vitals </strong>
                        <div class="card-header-actions">
                            <CLink class="card-header-action btn-minimize" @click="showVitals = !showVitals">
                                <CIcon :name="`cil-chevron-${showVitals ? 'bottom' : 'top'}`"/>
                            </CLink>
                        </div>
                    </CCardHeader>
                    <CCollapse :show="showVitals" :duration="400">
                        <CCardBody>
                            <vue-json-pretty
                                    :path="'res'"
                                    :data="avatar.vitals">
                            </vue-json-pretty>
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </CCol>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-puzzle"/><strong> Location </strong>
                        <div class="card-header-actions">
                            <CLink class="card-header-action btn-minimize" @click="showLoc = !showLoc">
                                <CIcon :name="`cil-chevron-${showLoc ? 'bottom' : 'top'}`"/>
                            </CLink>
                        </div>
                    </CCardHeader>
                    <CCollapse :show="showLoc" :duration="400">
                        <CCardBody>
                            <vue-json-pretty
                                    :path="'res'"
                                    :data="avatar.location">
                            </vue-json-pretty>
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import VueJsonPretty from 'vue-json-pretty'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import 'vue-json-pretty/lib/styles.css';

    export default {
        name: "Character",
        props: [ "id" ],
        components: {
            VueJsonPretty,
            Loading
        },
        data() {
            return {
                isLoading: false,
                avatar: {},
                player: {},
                showAll: false,
                showVitals: false,
                showLoc: false,
            }
        },
        computed: {
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        methods: {
            getCharacter() {
                this.isLoading = true;

                this.$http.get(this.$config.gameapi + '/Player/' + localStorage.env + '/character/' + this.id, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.avatar = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.getOwner();
                });
            },
            getOwner() {
                this.$http.get(this.$config.gameapi + '/Player/' + localStorage.env + '/' + this.avatar.userId, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.player = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.isLoading = false;
                });
            },
            toggleAll() {
                this.showAll = !this.showAll;
                this.showVitals = this.showAll;
                this.showLoc = this.showAll;
            }
        },
        created() {
          this.getCharacter();
        }
    }
</script>
